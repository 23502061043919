import React from "react";
import { Route, Switch, Redirect, withRouter, useLocation, useRouteMatch, useParams } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from 'antd';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH, TC_STAGING_URL, TC_API_STAGING_URL, TC_PROD_URL, TC_API_PROD_URL, TC_INTERNAL_URL, TC_API_INTERNAL_URL } from 'configs/AppConfig'
import useBodyClass from 'hooks/useBodyClass';
import TaskPrintLayout from "layouts/task-print-layout";
import axios from 'axios';

export const Views = (props) => {
  const { locale, location, direction } = props;
  const currentAppLocale = AppLocale[locale];
  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');
  const name = new URLSearchParams(search).get('name');
  const email = new URLSearchParams(search).get('email');
  const profile_pic = new URLSearchParams(search).get('profile_pic');
  const param = useParams()
  const match = useRouteMatch();
  let url_base = window.location.href;
  console.log(token);
  
  useBodyClass(`dir-${direction}`);
  
  React.useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
      localStorage.setItem("name", name);
      localStorage.setItem("email", email);
      localStorage.setItem("profile_pic", profile_pic);
      console.log(token);
      console.log(name);
      console.log(email);
      console.log(profile_pic);
    } 
    // else {
    //     localStorage.setItem("token", 
    //     'Y2xvNmFpbjluMDAwNXk4eGFncGFhN3ExdA.LS8r6fQLPYWFbPAOfP_mNHa8Sa-W_kFYZhtU_KCteiFGPK0ctVJPbcMmhVim');
    //   }
    
    if(url_base.includes(TC_INTERNAL_URL)) {
      axios.defaults.baseURL = TC_API_INTERNAL_URL
    }
    if(url_base.includes(TC_STAGING_URL)) {
      axios.defaults.baseURL = TC_API_STAGING_URL
    }
    if(url_base.includes(TC_PROD_URL)) {
      axios.defaults.baseURL = TC_API_PROD_URL
    }
    console.log(axios.defaults.baseURL)
  }, [])
  
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route exact path="/">
            <Redirect to={APP_PREFIX_PATH} />
          </Route>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} />
          </Route>
          <Route path={APP_PREFIX_PATH}>
            <AppLayout direction={direction} location={location}/>
          </Route>
          <Route path={`/task-completion-report-print/:id`} exact>
            <TaskPrintLayout direction={direction} location={location} id={param.id}/>
          </Route>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  )
}

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } =  theme;
  const { token } = auth;
  return { locale, token, direction }
};

export default withRouter(connect(mapStateToProps)(Views));